define(['app', '$location', '$window', 'siteObj', 'accessibilityAnnouncer'], function (app, $location, $window, siteObj, accessibilityAnnouncer) {

  var headerSearch = function () {
    var self = this;

    var _config = {
      data: {
        customerLocale: 'data-customer-locale',
        currencyType: 'data-currency',
        searchResultsContainer: 'data-search-results',
        hasImprovedSearch: 'data-has-improved-search'
      },
      selectors: {
        hideHeaderSearchAutoComplete: 'data-hide-autocomplete',
        toggleFormButton: '[data-toggle-form]',
        toggleFormButtonSpyglass: '.headerSearch_spyglass',
        form: '[data-form]',
        searchField: '[data-search]',
        option: '[data-option]',
        searchResultsContainer: '[data-search-results]',
        hideSearchResultsButton: '[data-hide-search-results-button]',
        noRecentSearchesMessage: '.headerSearch_improvedSearch_noRecentSearches',
        recentSearchesList: '.headerSearch_resultsList_recentSearches',
        viewAll: '[data-view-all]',
        bestSellersLink: '.headerSearch_resultsListProductLink',
        bestSellersWrapper: '.improvedSearchBestSellersWrapper',
        blogItem: '.headerSearch_resultsBlog'
      },
      classes: {
        toggleOpen: 'headerSearch_toggleForm-open',
        showForm: 'headerSearch_form-show',
        active: 'headerSearch_resultsListItem-active',
        searchResultsContainer: 'headerSearch_resultsContainer',
        searchFieldVisibleResults: 'headerSearch_input-resultsVisible',
        hideSearchResults: 'headerSearch_results-hide',
        showHideSearchResultsButton: 'headerSearch_hideResults-show',
        spyglassHidden: 'headerSearch_spyglass-hidden',
        headerSearchOverlay: 'headerSearch_overlay',
        showHeaderSearchOverlay: 'headerSearch_overlay-show',
        resultsListItem: 'headerSearch_resultsListItem',
        resultsListItemLink: 'headerSearch_resultsListLink',
      },
      queryExecuted: false,
      searchEndpointSuffix: '/headerSearch_results/headersearch.nav?autocompleteRequest=',
      fullSearchEndpoint: null,
      lastSearchString: null,
      lastKeyPress: null,
      lastSearchTimeout: null,
      specialKeys: {
        'UP': 38,
        'DOWN': 40,
        'RETURN': 13,
        'ESC': 27,
        'TAB': 9,
      },
      visibleResults: false,
      results: [],
      selectedIndex: null,
      previousSelected: null,
      minChars: 1,
      error: false,
      checkingFocus: false,
      mobileBreakpoint: 900,
      noRecentSearchesDisplayed: 5,
      recentSearchesStoragePrefix: "recent-searches-",
    };

    var _init = function (element) {
      self.element = element;
      self.westEndHeader = document.querySelector('[data-component=westendHeader]');
      self.toggleFormButton = self.element.querySelector(self.config.selectors.toggleFormButton);
      self.toggleFormButtonSpyglass = self.toggleFormButton.querySelector(self.config.selectors.toggleFormButtonSpyglass);
      self.form = self.element.querySelector(self.config.selectors.form);
      self.searchField = self.element.querySelector(self.config.selectors.searchField);
      self.customerLocale = self.element.getAttribute(self.config.data.customerLocale);
      self.currency = self.element.getAttribute(self.config.data.currencyType);
      self.searchResults = null;
      self.searchResultsContainer = null;
      self.hideSearchResultsButton = self.element.querySelector(self.config.selectors.hideSearchResultsButton);
      self.hasImprovedSearch = (self.element.getAttribute(self.config.data.hasImprovedSearch) === 'true');
      self.config.translations = {
        correction: self.element.dataset.translationCorrection,
        suggestion: self.element.dataset.translationSuggestion,
        product: self.element.dataset.translationProduct,
        resultsAvailable: self.element.dataset.translationResultsAvailable,
        resultsDesktopInstructions: self.element.dataset.translationResultsDesktopInstructions,
        lastResult: self.element.dataset.translationLastResult,
      };

      self.config.fullSearchEndpoint = '/' + self.customerLocale + '/' + self.currency + self.config.searchEndpointSuffix;
      if (self.westEndHeader) {
        self.overlayFocus = self.westEndHeader.querySelector('[data-js-element=headerSearch_overlay]');
        self.buttonSearch = self.westEndHeader.querySelector('[data-js-element=headerSearch_button]');
        self.buttonSearch.disabled = false;
      }

      self.addEventListeners();
      self.createSearchResultsContainer();
      self.checkForParam();

      app.subscribe('SHOW-HEADER-SEARCH-FORM', (value) => {
        self.toggleForm();
        self.searchField.value = value;
      });

      app.subscribe('HEADER-SEARCH-THAT-SCROLLS-VISIBLE', (isVisible) => {
        if (isVisible) {
          self.toggleFormButtonSpyglass.classList.add(self.config.classes.spyglassHidden);
        } else {
          self.toggleFormButtonSpyglass.classList.remove(self.config.classes.spyglassHidden);
        }
      });

      return self;
    };

    var _addEventListeners = function () {
      self.toggleFormButton.addEventListener('click', self.toggleForm);
      self.searchField.addEventListener('keydown', self.keyDownEventHandler);
      self.searchField.addEventListener('keyup', self.keyUpEventHandler);
      self.searchField.addEventListener('focus', self.focusEventHandler);
      self.hideSearchResultsButton.addEventListener('click', self.hideSearchResults);
      if (self.overlayFocus) {
        self.overlayFocus.addEventListener('click', self.closeSearchForm);
        document.addEventListener('focusin', event => {
          (!self.element.contains(event.target) && app.element.hasClass(self.config.classes.showHeaderSearchOverlay, self.overlayFocus)) && self.closeSearchForm();
        });
      }
      self.hasImprovedSearch && self.form.addEventListener('submit', self.addToSearchHistory);
    };

    var _addToSearchHistory = () => {
      const searchValue = self.config.lastSearchString;
      const site = siteObj.siteCode;
      if (searchValue && site) {
        try {
          const currentString = localStorage.getItem(self.config.recentSearchesStoragePrefix + site);
          if(currentString){
            const currentList = JSON.parse(currentString)
            const newListLength = currentList.unshift(searchValue);
            let newList = currentList;
            if(newListLength > self.config.noRecentSearchesDisplayed) {
              newList = currentList.slice(0, self.config.noRecentSearchesDisplayed)
            }
            localStorage.setItem(self.config.recentSearchesStoragePrefix + site, JSON.stringify(newList))
          } else {
            const newEntry = JSON.stringify([searchValue])
            localStorage.setItem(self.config.recentSearchesStoragePrefix + site, newEntry)
          }
        } catch (e) {
          if (e == QUOTA_EXCEEDED_ERR) {
            localStorage.clear();
            // delete storage to make room for more...
          }
        }
      }
    }

    var _populateRecentSearches = () => {
      const site = siteObj.siteCode;
      const recentSearchesJson = site && localStorage.getItem(self.config.recentSearchesStoragePrefix + site);
      const noRecentSearchesDiv = self.element.querySelector(self.config.selectors.noRecentSearchesMessage);
      const recentSearchesList = self.element.querySelector(self.config.selectors.recentSearchesList);

      if (recentSearchesList) {
        recentSearchesList.innerHTML='';
        if (recentSearchesJson) {
          const recentSearches = JSON.parse(recentSearchesJson);
          recentSearches.forEach((recentSearch) => {
            let newEntry = document.createElement('li');
            newEntry.classList.add(self.config.classes.resultsListItem);
            let newEntryLink = document.createElement('a');
            newEntryLink.classList.add(self.config.classes.resultsListItemLink)
            newEntryLink.innerText = recentSearch;
            newEntryLink.addEventListener('click', self.makeRecentSearch)
            newEntry.appendChild(newEntryLink);
            recentSearchesList.appendChild(newEntry);
          });
          noRecentSearchesDiv && (noRecentSearchesDiv.style.display = "none");
        } else {
          noRecentSearchesDiv && (noRecentSearchesDiv.style.display = "block");
        }
      }
    }

    var _makeRecentSearch = (e) => {
      const searchTerm = e.target.innerText;
      self.searchField.value = searchTerm;
      self.form.submit();
      app.publish('tracking/record', 'Recent searches', 'click', searchTerm)
    }

    var _createSearchResultsContainer = function () {
      var container = document.createElement('div');
      container.setAttribute('class', self.config.classes.searchResultsContainer);
      container.setAttribute(self.config.data.searchResultsContainer, '');
      self.element.appendChild(container);
      self.searchResultsContainer = self.element.querySelector(self.config.selectors.searchResultsContainer);
    };

    var _toggleForm = function (e) {
      self.toggleButtonClass();
      self.toggleShowForm();
    };

    var _toggleButtonClass = function () {
      if (app.element.hasClass(self.config.classes.toggleOpen, self.toggleFormButton)) {
        app.element.removeClass(self.config.classes.toggleOpen, self.toggleFormButton);
        self.hideSearchResults();
        app.element.setAttribute('aria-label', siteObj.props.accessibility.openSearch, self.toggleFormButton);

        if (self.overlayFocus) {
          app.element.removeClass(self.config.classes.showHeaderSearchOverlay, self.overlayFocus);
        }

        if (self.buttonSearch) {
          app.element.removeClass('headerSearch_button-show', self.buttonSearch);
        }
      } else {
        app.element.addClass(self.config.classes.toggleOpen, self.toggleFormButton);
        app.element.setAttribute('aria-label', siteObj.props.accessibility.closeSearch, self.toggleFormButton);
        if (self.overlayFocus) {
          app.element.addClass(self.config.classes.showHeaderSearchOverlay, self.overlayFocus);
        }
      }
    };

    var _closeSearchForm = function () {
      if (self.overlayFocus) {
        app.element.removeClass(self.config.classes.showHeaderSearchOverlay, self.overlayFocus);
        app.element.removeClass('headerSearch_form-overlay', self.form);
      }

      if (self.buttonSearch) {
        self.buttonSearch.disabled = true;
        app.element.removeClass('headerSearch_button-show', self.buttonSearch);
      }

      app.element.removeClass(self.config.classes.toggleOpen, self.toggleFormButton);
      self.hideSearchResults();
      app.element.removeClass(self.config.classes.showForm, self.form);
      self.config.searchFieldVisible = false;
    };

    var _toggleShowForm = function () {
      if (app.element.hasClass(self.config.classes.showForm, self.form)) {
        app.element.removeClass(self.config.classes.showForm, self.form);
        self.config.searchFieldVisible = false;
      } else {
        app.element.addClass(self.config.classes.showForm, self.form);
        self.config.searchFieldVisible = true;
        self.searchField.focus();
      }
    };

    var _doSearch = function (showBestSellers) {
      self.config.queryExecuted = true;
      self.config.error = false;
      if (self.buttonSearch) {
        app.element.addClass('headerSearch_button-show', self.buttonSearch);
        self.buttonSearch.disabled = false;
      }

      if(self.hasImprovedSearch && self.config.lastSearchString == null){
        self.config.lastSearchString = "";
      }

      return app.ajax.get({
        url: self.config.fullSearchEndpoint + encodeURIComponent(self.config.lastSearchString) + `&showBestSellers=${showBestSellers}`,
        dataType: 'JSON',
        error: self.errorCallback,
        success: self.populateSearchResults,
      });
    };

    var _errorCallback = function () {
      self.config.error = true;
    };

    var _checkForParam = function () {
      const match = $location.href.match(/[\?&]search=([^&]*)/);
      if (match) {
        self.searchField.value = decodeURIComponent(match[1]).replace(/\+/g, ' ');
      }
    };

    var _keyUpEventHandler = function (e) {
      if(self.hasImprovedSearch && self.searchField.value.length === 0) {
        self.config.lastSearchString = self.searchField.value;
        self.doSearch(true);
      }

      if (self.element.hasAttribute(self.config.selectors.hideHeaderSearchAutoComplete)) return false;

      self.config.lastKeyPress = e.keyCode;

      if (e.keyCode === self.config.specialKeys.UP) return self.keyNavigationHandler('up');
      if (e.keyCode === self.config.specialKeys.DOWN) return self.keyNavigationHandler('down');
      if (e.keyCode === self.config.specialKeys.ESC) return self.hideSearchResults();

      self.config.lastSearchString = e.target.value;

      if (e.target.value.length < self.config.minChars) {
        if (e.keyCode !== self.config.specialKeys.RETURN) {
          return self.hideSearchResults();
        }
      }

      clearTimeout(self.lastSearchTimeout);
      self.lastSearchTimeout = setTimeout( () => {self.doSearch(false)}, 200);
    };

    var _keyDownEventHandler = function (e) {
      self.config.lastKeyPress = e.keyCode;

      if (e.keyCode === self.config.specialKeys.RETURN) {
        // only change the behaviour if there is a selected item in the autocomplete list
        // otherwise it will interfere with the default form submit
        if (self.config.selectedIndex !== null) {
          (e.preventDefault) ? e.preventDefault() : e.returnValue = false;
          self.config.results[self.config.selectedIndex].children[0].click();
        }
      }
    };

    var _keyNavigationHandler = function (direction) {
      if (self.config.results.length) {
        if (direction === 'up') {
          if (self.config.selectedIndex === null || self.config.selectedIndex === 0) {
            self.config.selectedIndex = null;
          } else {
            self.config.selectedIndex--;
          }
        } else if (direction === 'down') {
          if (self.config.selectedIndex === null) {
            self.config.selectedIndex = 0;
          } else if (self.config.selectedIndex >= self.config.results.length - 1) {
            self.config.selectedIndex = self.config.results.length - 1;
          } else {
            self.config.selectedIndex++;
          }
        }

        if (self.config.previousSelected !== null) {
          app.element.removeClass(self.config.classes.active, self.config.previousSelected);
        }

        if (self.config.selectedIndex !== null) {
          app.element.addClass(self.config.classes.active, self.config.results[self.config.selectedIndex]);
          self.config.previousSelected = self.config.results[self.config.selectedIndex];

          const lastPrefix = self.config.selectedIndex >= self.config.results.length - 1 ? `${self.config.translations.lastResult}, ` : '';
          const resultPrefix = self.config.translations[self.config.results[self.config.selectedIndex].dataset.optionType];
          const resultText = `${lastPrefix}${resultPrefix}: ${self.config.results[self.config.selectedIndex].innerText.replace(/\n/g, ' ')}`;

          resultText && accessibilityAnnouncer.announce('assertive', resultText);
        }
      }
    };

    var _focusEventHandler = function (e) {
      if (self.overlayFocus) {
        app.element.addClass(self.config.classes.showHeaderSearchOverlay, self.overlayFocus);
        app.element.addClass('headerSearch_form-overlay', self.form);
        if (self.hasImprovedSearch) {
          (e.target.value!=="") && (self.config.lastSearchString = e.target.value);
          self.doSearch(self.searchField.value.length < self.config.minChars);
        }
      }

      if (self.config.queryExecuted && e.target.value.length >= self.config.minChars
        && e.target.value === self.config.lastSearchString) {
        self.showSearchResults();
      }
    };

    var _hideSearchResults = function () {
      if (self.config.visibleResults) {
        app.element.addClass(self.config.classes.hideSearchResults, self.searchResultsContainer);
        app.element.removeClass(self.config.classes.searchFieldVisibleResults, self.searchField);
        self.config.selectedIndex = null;
        self.config.visibleResults = false;
        self.hideHideSearchResultsButton();

        if(!self.hasImprovedSearch) {
          self.searchField && self.searchField.focus();
        }
      }
    };

    var _populateSearchResults = function (response) {
      self.searchResultsContainer.innerHTML = response;
      self.config.results = self.searchResultsContainer.querySelectorAll(self.config.selectors.option);

      if(self.hasImprovedSearch) {
        self.showSearchResults();
        self.populateRecentSearches();
        const blogItem = document.querySelectorAll(self.config.selectors.blogItem);
        blogItem && blogItem.forEach(item => {
          item.addEventListener('click', () => {
            app.publish('columbo/track', 'improvedSearch', 'blog click');
            app.publish('tracking/record', 'improvedSearch', 'blog', 'click');
          })
        })
      }

      if (self.searchField.value.length >= self.config.minChars) {
        self.showSearchResults();
      }

      self.viewAll = document.querySelectorAll(self.config.selectors.viewAll);
      if(self.viewAll){
        self.viewAll.forEach( button => {
          button.addEventListener('click', () => {
            self.buttonSearch.click();
          });
        })
      }
    };

    var _showSearchResults = function () {
      app.element.removeClass(self.config.classes.hideSearchResults, self.searchResultsContainer);
      app.element.addClass(self.config.classes.searchFieldVisibleResults, self.searchField);
      self.config.visibleResults = true;


      if ($window.innerWidth > self.config.mobileBreakpoint){
        accessibilityAnnouncer.announce('assertive', `${self.config.translations.resultsAvailable}, ${self.config.translations.resultsDesktopInstructions}`);
      } else {
        accessibilityAnnouncer.announce('assertive', self.config.translations.resultsAvailable);
      }

      self.showHideSearchResultsButton();

      const bestSellersWrapper = self.element.querySelector(self.config.selectors.bestSellersWrapper);
      const bestSellerLinks = bestSellersWrapper && bestSellersWrapper.querySelectorAll(self.config.selectors.bestSellersLink)
      bestSellerLinks && bestSellerLinks.forEach((link) => {
        link.addEventListener('click', () => {
          const href = link.getAttribute('href');
          href && app.publish('tracking/record', 'Best Sellers', 'click', href);
          href && app.publish('columbo/track', 'Best Sellers', 'click', href);
        })
      });
    };

    var _showHideSearchResultsButton = function () {
      app.element.addClass(self.config.classes.showHideSearchResultsButton, self.hideSearchResultsButton);
    };

    var _hideHideSearchResultsButton = function () {
      self.searchField.value = '';
      app.element.removeClass(self.config.classes.showHideSearchResultsButton, self.hideSearchResultsButton);
      if (self.buttonSearch) {
        self.buttonSearch.disabled = true;
        app.element.removeClass('headerSearch_button-show', self.buttonSearch);
      }
    };

    self.config = _config;
    self.init = _init;
    self.addEventListeners = _addEventListeners;
    self.createSearchResultsContainer = _createSearchResultsContainer;
    self.toggleForm = _toggleForm;
    self.closeSearchForm = _closeSearchForm;
    self.toggleButtonClass = _toggleButtonClass;
    self.toggleShowForm = _toggleShowForm;
    self.doSearch = _doSearch;
    self.errorCallback = _errorCallback;
    self.focusEventHandler = _focusEventHandler;
    self.keyDownEventHandler = _keyDownEventHandler;
    self.keyNavigationHandler = _keyNavigationHandler;
    self.keyUpEventHandler = _keyUpEventHandler;
    self.hideSearchResults = _hideSearchResults;
    self.populateSearchResults = _populateSearchResults;
    self.showSearchResults = _showSearchResults;
    self.checkForParam = _checkForParam;
    self.showHideSearchResultsButton = _showHideSearchResultsButton;
    self.hideHideSearchResultsButton = _hideHideSearchResultsButton;
    self.addToSearchHistory = _addToSearchHistory;
    self.populateRecentSearches = _populateRecentSearches;
    self.makeRecentSearch = _makeRecentSearch;
  };

  return headerSearch;
});
